/**
 * Tapper | page style
 */

html,
body,
.container {
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.content {
    max-width: 540px;

    padding: 12px 0;
    margin-top: auto;
    margin-bottom: auto;

    text-align: center;
}

#levelIcon {
    font-size: 4rem;

    margin-top: 1.5rem;
    margin-bottom: 0;
}
