html, body, .container {
  height: 100%;
}

.container {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.content {
  text-align: center;
  max-width: 540px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 12px 0;
}

#levelIcon {
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 4rem;
}
/*# sourceMappingURL=index.ca91d148.css.map */
